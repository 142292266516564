/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { RenderSections } from '@components/UI/Common/SectionCommonUI';
import PlannedGiftsOtherWaysToGive from '@sections/donation/PlannedGiftsOtherWaysToGive';

/**
 * Renders the Donation Common UI component.
 * @param {Array} pageBody - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} Donation Common UI Component.
 */
const DonationCommonUI = ({ pageBody, region, lang, pageName }) => {
  let plannedGiftTabData = [];
  return (
    <Box as="section" id="centers-section" className="btn-center">
      {pageBody && pageBody.length
        ? pageBody.map((section, i) => {
            if (
              // section.sectionId === 'tab-index-section' ||
              section.sectionId === 'planned-gifts' ||
              section.sectionId === 'other-ways-to-give'
            ) {
              plannedGiftTabData.push({
                id: section.sectionId,
                data: section.sectionContent,
              });
              return (
                plannedGiftTabData?.length > 1 && (
                  <PlannedGiftsOtherWaysToGive
                    plannedGiftTabData={plannedGiftTabData}
                    region={region}
                    lang={lang}
                  />
                )
              );
            } else if (section.sectionId === 'tab-index-section') {
              return <></>;
            }
            return (
              <RenderSections
                section={section}
                region={region}
                lang={lang}
                pType="others"
                key={i}
                pageName={pageName}
              />
            );
          })
        : null}
    </Box>
  );
};

export default DonationCommonUI;
