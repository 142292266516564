/* Built In Imports */
import { useContext, useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Breadcrum from '@components/Navigation/Breadcrum';
import DonationCommonUI from '@components/UI/Donation/DonationCommonUI';
import HeadComponent from '@components/Utility/HeadComponent';
import config from '@config';
import WrapLayout from '@Layout/WrapLayout';
import { AuthContext } from '@store/auth-context';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { DonateFormProvider } from '@store/donateFormContext';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
} from 'services/commonService';
import { fetchDetails, getLandingPaths } from 'services/donationService';

/* Styles */

/**
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Events Landing Page Data
 */
const Donation = ({ region, language, pageData }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.RegionAndLang(region, language);
  }, [authContext, region, language]);

  // consoleLog('page', pageData);
  return (
    <WrapLayout>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Breadcrum
        page={pageData?.breadcrumbs}
        alsoin={pageData?.alsoin}
        url=""
        region={region}
        lang={language}
        pageConfig={pageData?.pageConfig}
      />
      <Box
        as="section"
        className="container-article"
        p="0"
        maxW="1300"
        w="100%"
        mx="auto"
      >
        <DonateFormProvider>
          <DonationCommonUI
            pageBody={pageData?.body}
            region={region}
            lang={language}
          />
        </DonateFormProvider>
      </Box>
    </WrapLayout>
  );
};

export const getStaticPaths = async () => {
  const donationLandingPaths = config.USE_LOCAL_URL
    ? [`/us/en/donation`, `/ca/en/donation`, `/in/en/donation`]
    : await getLandingPaths('lvl0', '/donation');
  return {
    paths: donationLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region, language },
  req,
}) => {
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale(region, language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, language),
    'isomainmenu'
  );
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchDetails(region, language, '', {
    preview,
    data: previewData,
  });

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: region,
      language: language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      isPreview: preview ? true : false,
    },
  };
};

export default Donation;
